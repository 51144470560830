import { BackButton } from 'common-front/src/components/buttons/backButton';
import { TextInput } from 'common-front/src/designSystem/components/textInput';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { useStateDebounce } from 'common-front/src/hooks/useStateDebounce';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { I } from 'common/src/designSystem/components/i';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { MembersPaths } from 'common/src/util/paths/membersPaths';
import { isNonEmptyString } from 'common/src/util/string';
import * as React from 'react';
import { useMemberDocumentsQuery } from '../../../generated/graphqlHooks';
import { Document, DocumentSkeleton } from '../../common/documents/document';
import { SpaceContainer } from '../../common/spaceContainer';

export const MemberDocumentsList = () => {
    const {
        history,
        translate,
        params: { organizationId, eventId, userInfoId }
    } = useHeavent();
    const [name, nameDebounced, setName] = useStateDebounce('');
    const { data, isLoading } = useMemberDocumentsQuery({
        organizationId,
        userInfoId,
        name: isNonEmptyString(nameDebounced) ? nameDebounced : undefined
    });

    return (
        <SpaceContainer
            isLoading={isLoading}
            organization={data.organization}
            spaceDesign={{
                themeColors: data.organization?.membersSpaceDesign?.colors,
                bannerSrc: data.organization?.membersSpaceDesign?.banner?.url,
                logoSrc: data.organization?.membersSpaceDesign?.logo?.url
            }}
        >
            <BackButton
                color="primary"
                fontWeight="medium"
                returnPathFallback={MembersPaths.MEMBER_REGISTRATION({
                    organizationId,
                    eventId,
                    userInfoId
                })}
            >
                {translate('retour_l_accu_00810')}
            </BackButton>

            <Box
                color="primary700"
                css={{ cursor: 'pointer' }}
                fontWeight="medium"
                onClick={() => {
                    history.push(MembersPaths.MEMBER(organizationId, userInfoId));
                }}
            >
                <I icon="arrow-left" /> {translate('retour_l_accu_00810')}
            </Box>

            <Spacer height={{ '@initial': '2', '@desktop': '3' }} />

            <Box font="gray900 displayXs semiBold">{translate('vos_documents_03830')}</Box>

            <Spacer height={{ '@initial': '6', '@tablet': '7', '@desktop': '8' }} />

            <Flex
                css={{
                    background: '$gray100',
                    borderRadius: '$1',
                    padding: '$4'
                }}
                width={1}
            >
                <TextInput
                    icon="magnifying-glass"
                    placeholder={translate('rechercher_un_d_38511')}
                    value={name}
                    onChange={setName}
                />
            </Flex>

            <Spacer height="6" />

            {isLoading ? (
                <>
                    <DocumentSkeleton />
                    <Spacer height="4" />
                    <DocumentSkeleton />
                </>
            ) : (
                data.userInfo.documents.nodes.map((document, index) => (
                    <React.Fragment key={document.id}>
                        <Document document={document} />

                        {index !== data.userInfo.documents.nodes.length - 1 && (
                            <Spacer height="4" />
                        )}
                    </React.Fragment>
                ))
            )}
        </SpaceContainer>
    );
};
