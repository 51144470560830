import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { Link } from 'common/src/designSystem/components/link';
import { Delegation } from 'common/src/generated/types';
import { useParams } from 'common/src/util/dependencies/dependencies';
import { DelegationsPaths } from 'common/src/util/paths/delegationsPaths';
import * as React from 'react';

interface IDelegationsListDelegationProps {
    delegation: Pick<Delegation, 'id' | 'name' | 'eventId'>;
}

export const DelegationsListDelegation = ({ delegation }: IDelegationsListDelegationProps) => {
    const { organizationId } = useParams();

    return (
        <Flex
            align="center"
            css={{
                background: 'white',
                bd: true,
                padding: '$4 $6'
            }}
            gap="3"
            width={1}
        >
            <Link
                to={DelegationsPaths.DELEGATION({
                    delegationId: delegation.id,
                    eventId: delegation.eventId || undefined,
                    organizationId
                })}
                width={1}
            >
                <Box font="gray800 textSm medium">{delegation.name}</Box>
            </Link>
        </Flex>
    );
};
