import { Blank } from 'common-front/src/components/blank/blank';
import { Svgs } from 'common-front/src/util/assets';
import { Box } from 'common/src/designSystem/components/box';
import { RichText } from 'common/src/designSystem/components/richEditor/richText';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import { isNonEmptyString } from 'common/src/util/string';
import { noop } from 'lodash-es';
import * as React from 'react';
import { FormBox } from './formBox';
import { FormContainer } from './formContainer';

interface IFormClosedProps {
    bannerUrl?: string;
    closedMessage: string;
    closedTitle: string;
}

export const FormClosed = (props: IFormClosedProps) => {
    const translate = useTranslate();

    return (
        <FormContainer bannerUrl={props.bannerUrl}>
            <FormBox
                canSelect={false}
                isCompleted={false}
                isOpen={true}
                number={1}
                title={
                    isNonEmptyString(props.closedTitle)
                        ? props.closedTitle
                        : translate('formulaire_cl_t_92617')
                }
                onSelect={noop}
            >
                {isNonEmptyString(props.closedMessage) ? (
                    <RichText text={props.closedMessage} />
                ) : (
                    <Box height={250} width={1}>
                        <Blank imageSrc={Svgs.Done} title={translate('ce_formulaire_a_73045')} />
                    </Box>
                )}
            </FormBox>

            <Spacer height="9" />
        </FormContainer>
    );
};
