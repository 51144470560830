import { Button } from 'common-front/src/designSystem/components/button';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { useTabletQuery } from 'common-front/src/hooks/useTabletQuery';
import { Avatar } from 'common/src/components/avatar/avatar';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { ProfilesQuery } from 'common/src/generated/types';
import { MembersPaths } from 'common/src/util/paths/membersPaths';
import { isNonEmptyString } from 'common/src/util/string';
import * as React from 'react';

interface IProfilesProfileProps {
    userInfo: ProfilesQuery['user']['usersInfos'][0];
}

export const ProfilesProfile = (props: IProfilesProfileProps) => {
    const {
        history,
        translate,
        params: { organizationId }
    } = useHeavent();
    const isTablet = useTabletQuery();

    return (
        <Flex
            align="center"
            css={{
                background: 'white',
                bd: true,
                cursor: 'pointer',
                padding: '$4 $6'
            }}
            gap="3"
            onClick={() => {
                history.push(MembersPaths.MEMBER(organizationId, props.userInfo.id));
            }}
        >
            <Avatar
                email={props.userInfo.email}
                image={props.userInfo.picture?.url}
                name={props.userInfo.name}
                size={40}
            />

            <Flex css={{ flex: '1', overflow: 'hidden' }} direction="column">
                <Box color="gray800" css={{ ellipsis: true }} fontWeight="medium">
                    {isNonEmptyString(props.userInfo.name) ? props.userInfo.name : '-'}
                </Box>

                <Box color="gray500" css={{ ellipsis: true }}>
                    {props.userInfo.email}
                </Box>
            </Flex>

            {isTablet ? (
                <Button color="white" size="sm">
                    {translate('voir_son_profil_99428')}
                </Button>
            ) : (
                <Button color="white" leftIcon="arrow-right" size="sm" />
            )}
        </Flex>
    );
};
