import { Button } from 'common-front/src/designSystem/components/button';
import { LabelOptional } from 'common-front/src/designSystem/components/input/labelOptional';
import { Textarea } from 'common-front/src/designSystem/form/textarea';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { Cell } from 'common/src/designSystem/components/table/cell';
import { HeaderCell } from 'common/src/designSystem/components/table/headerCell';
import { HeaderRow } from 'common/src/designSystem/components/table/headerRow';
import { Row } from 'common/src/designSystem/components/table/row';
import { Table } from 'common/src/designSystem/components/table/table';
import {
    EventId,
    FormId,
    FormTeamSummaryQuery,
    RegisterSlotDisplay,
    TeamCode
} from 'common/src/generated/types';
import { DateTimeService } from 'common/src/services/dateTimeService';
import { IntervalService } from 'common/src/services/intervalService';
import { isNonEmptyArray } from 'common/src/util/array';
import { assertUnreachable } from 'common/src/util/assertUnreachable';
import { useService, useTranslate } from 'common/src/util/dependencies/dependencies';
import { LocaleFormats } from 'common/src/util/luxon';
import { Interval } from 'luxon';
import * as React from 'react';
import { useFormTeamSummaryQuery } from '../../../../generated/graphqlHooks';

interface IFormTeamSummaryInnerProps {
    formId: FormId;
    slotDisplay: RegisterSlotDisplay;
    team: NonNullable<FormTeamSummaryQuery['event']['team']>;
}

const FormTeamSummaryInner = (props: IFormTeamSummaryInnerProps) => {
    const translate = useTranslate();
    const dateTimeService = useService(DateTimeService);
    const intervalService = useService(IntervalService);
    const positions = React.useMemo(
        () =>
            props.team.positionsCategories
                .map((category) => ({
                    name: category.name
                }))
                .concat(
                    props.team.positions.map((position) => ({
                        name: position.name
                    }))
                )
                .concat(
                    props.team.positionsSlots.map((slot) => ({
                        name: slot.nameOrRange
                    }))
                ),
        [props.team]
    );
    const renderRange = React.useCallback(
        (range: Interval) => {
            switch (props.slotDisplay) {
                case RegisterSlotDisplay.Calendar:
                case RegisterSlotDisplay.DisplayDays:
                    return dateTimeService.toLocaleString(
                        range.start!,
                        LocaleFormats.DateOnly.WeekdayLongMonthLong
                    );
                case RegisterSlotDisplay.Custom:
                case RegisterSlotDisplay.Display:
                    return intervalService.toDisplayString(range, {
                        formats: {
                            displayWeekday: true
                        }
                    });
                case RegisterSlotDisplay.Hide:
                    return null;
                default:
                    return assertUnreachable(props.slotDisplay);
            }
        },
        [props.slotDisplay]
    );

    return (
        <>
            {isNonEmptyArray(props.team.slots) && (
                <>
                    <Flex
                        css={{
                            background: 'white',
                            border: '1px solid $gray200',
                            borderBottom: 'none',
                            borderTopLeftRadius: '$2',
                            borderTopRightRadius: '$2',
                            padding: '$5 $6'
                        }}
                    >
                        <Box font="gray900 textMd medium">{translate('disponibilit_s_49923')}</Box>
                    </Flex>

                    <Table>
                        <HeaderRow css={{ borderRadius: '0 !important' }}>
                            <HeaderCell>{translate('Date')}</HeaderCell>
                        </HeaderRow>

                        {props.team.slots.map((slot, index) => (
                            <Row key={index}>
                                <Cell css={{ textTransform: 'capitalize' }}>
                                    {renderRange(slot.range)}
                                </Cell>
                            </Row>
                        ))}
                    </Table>

                    <Spacer height="6" />
                </>
            )}

            {isNonEmptyArray(positions) && (
                <>
                    <Flex
                        css={{
                            background: 'white',
                            border: '1px solid $gray200',
                            borderBottom: 'none',
                            borderTopLeftRadius: '$2',
                            borderTopRightRadius: '$2',
                            padding: '$5 $6'
                        }}
                    >
                        <Box font="gray900 textMd medium">{translate('missions_souhai_23225')}</Box>
                    </Flex>

                    <Table>
                        <HeaderRow css={{ borderRadius: '0 !important' }}>
                            <HeaderCell>{translate('Position')}</HeaderCell>
                        </HeaderRow>

                        {positions.map((position, index) => (
                            <Row key={index}>
                                <Cell>{position.name}</Cell>
                            </Row>
                        ))}
                    </Table>

                    <Spacer height="6" />
                </>
            )}

            <Textarea
                name={`formRegister.userInfo.fields.form${props.formId}`}
                label={<LabelOptional>{translate('commentaire_r_12290')}</LabelOptional>}
            />
        </>
    );
};

interface IFormTeamSummaryProps {
    eventId: EventId;
    formId: FormId;
    registerButtonText: string;
    slotDisplay: RegisterSlotDisplay;
    teamCode: TeamCode;

    handleSummit(): void;
}

export const FormTeamSummary = (props: IFormTeamSummaryProps) => {
    const { data, loader } = useFormTeamSummaryQuery({
        eventId: props.eventId,
        teamCode: props.teamCode
    });

    return (
        loader || (
            <>
                <FormTeamSummaryInner
                    formId={props.formId}
                    slotDisplay={props.slotDisplay}
                    team={data.event.team!}
                />

                <Spacer height="7" />

                <Flex>
                    <Button onClick={props.handleSummit}>{props.registerButtonText}</Button>
                </Flex>
            </>
        )
    );
};
