import { Form } from 'common-front/src/components/form/form';
import { UserCreateField } from 'common-front/src/components/users/create/userCreateField';
import { Button } from 'common-front/src/designSystem/components/button';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { Flex } from 'common/src/designSystem/components/flex';
import { Spacer } from 'common/src/designSystem/components/spacer';
import {
    CustomFieldWithConditionFragment,
    DelegationUpdateFieldsInfosQuery
} from 'common/src/generated/types';
import { DelegationsPaths as DelegationsPathsV2 } from 'common/src/util/paths/delegationsPaths';
import * as React from 'react';
import {
    useDelegationUpdateFieldsInfosQuery,
    useDelegationUpdateFieldsMutation
} from '../../../../generated/graphqlHooks';

interface IDelegationUpdateFieldsComponentProps {
    customFields: CustomFieldWithConditionFragment[];
    delegation: DelegationUpdateFieldsInfosQuery['organization']['delegation'];
}

const DelegationUpdateFieldsComponent = (props: IDelegationUpdateFieldsComponentProps) => {
    const {
        history,
        translate,
        params: { organizationId, eventId, delegationId }
    } = useHeavent();
    const { mutate: delegationUpdateFields } = useDelegationUpdateFieldsMutation();
    const gotoDelegation = (goBack = false) =>
        goBack
            ? history.goBack(
                  DelegationsPathsV2.DELEGATION_DASHBOARD({ organizationId, eventId, delegationId })
              )
            : history.push(
                  DelegationsPathsV2.DELEGATION_DASHBOARD({ organizationId, eventId, delegationId })
              );

    return (
        <Form
            direction="column"
            width={1}
            initialValues={{
                fields: props.delegation.fields
            }}
            onSubmit={async (values) => {
                await delegationUpdateFields({
                    organizationId,
                    eventId,
                    delegationId,
                    fields: values.fields
                });

                gotoDelegation();
            }}
            render={({ form, handleSubmit, submitting }) => (
                <>
                    <Button
                        color="invisible"
                        leftIcon="arrow-left"
                        onClick={() => gotoDelegation(true)}
                    >
                        {translate('retour_92202')}
                    </Button>

                    <Spacer height="4" />

                    <Flex direction="column" gap="4">
                        {props.customFields.map((customField) => (
                            <UserCreateField
                                key={customField.id}
                                display={true}
                                fileAcl="public-read"
                                field={customField}
                                isMandatory={false}
                                organizationId={organizationId}
                                prefix="fields."
                                change={form.change}
                            />
                        ))}
                    </Flex>

                    <Spacer height="6" />

                    <Flex gap="4">
                        <Button isLoading={submitting} onClick={handleSubmit}>
                            {translate('mettre_jour_85141')}
                        </Button>

                        <Button color="white" onClick={() => gotoDelegation(true)}>
                            {translate('annuler_48254')}
                        </Button>
                    </Flex>
                </>
            )}
        />
    );
};

export const DelegationUpdateFields = () => {
    const {
        params: { organizationId, delegationId }
    } = useHeavent();
    const { data, loader } = useDelegationUpdateFieldsInfosQuery({ organizationId, delegationId });

    return (
        loader || (
            <DelegationUpdateFieldsComponent
                delegation={data.organization.delegation}
                customFields={data.organization.customFields.nodes}
            />
        )
    );
};
