import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { useParams, useTranslate } from 'common/src/util/dependencies/dependencies';
import * as React from 'react';
import { AsoAuthContainer } from '../../../auth/aso/asoAuthContainer';
import { OrganizationPublicContextProvider } from '../../../organizationPublicContext';
import { ProfilesProfile } from '../profilesProfile';
import { useProfilesList } from '../useProfilesList';
import { AsoProfilesListSkeleton } from './asoProfilesListSkeleton';

export const AsoProfilesList = () => {
    const translate = useTranslate();
    const { organizationId } = useParams();
    const { usersInfos, isLoading } = useProfilesList();

    return (
        <OrganizationPublicContextProvider
            isLoading={isLoading}
            loadDelegationsSpace={false}
            loadMembersSpace={true}
            organizationId={organizationId}
            skeleton={<AsoProfilesListSkeleton />}
        >
            <AsoAuthContainer>
                <Flex
                    css={{
                        width: '100%',
                        '@desktop': {
                            width: '$modalMd'
                        }
                    }}
                    direction="column"
                    gap="3"
                >
                    <Box color="gray800" fontSize="textMd" fontWeight="semiBold">
                        {translate('_quel_profil_s_67307')}
                    </Box>

                    {(usersInfos ?? []).map((userInfo) => (
                        <ProfilesProfile key={userInfo.id} userInfo={userInfo} />
                    ))}
                </Flex>
            </AsoAuthContainer>
        </OrganizationPublicContextProvider>
    );
};
