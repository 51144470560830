import { UserField } from 'common-front/src/components/users/show/userField';
import { Separator } from 'common-front/src/designSystem/components/separator';
import { Flex } from 'common/src/designSystem/components/flex';
import {
    CustomFieldWithConditionFragment,
    DelegationSpaceDashboardQuery
} from 'common/src/generated/types';
import * as React from 'react';

interface IDelegationCustomFieldsProps {
    customFields: CustomFieldWithConditionFragment[];
    delegation: DelegationSpaceDashboardQuery['organization']['delegation'];
}

export const DelegationCustomFields = (props: IDelegationCustomFieldsProps) => (
    <Flex direction="column" gap="4" width={1}>
        {props.customFields.map((cf, index) => (
            <React.Fragment key={cf.id}>
                {index !== 0 && <Separator direction="horizontal" />}

                <UserField field={cf} fields={props.delegation.fields} />
            </React.Fragment>
        ))}
    </Flex>
);
