import { Button } from 'common-front/src/designSystem/components/button';
import { Dropdown } from 'common-front/src/designSystem/components/dropdown/dropdown';
import { ItemIcon } from 'common-front/src/designSystem/components/dropdown/item';
import { Menu } from 'common-front/src/designSystem/components/dropdown/menu';
import { Trigger } from 'common-front/src/designSystem/components/dropdown/trigger';
import { useDesktopQuery } from 'common-front/src/hooks/useDesktopQuery';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { useTabletQuery } from 'common-front/src/hooks/useTabletQuery';
import { signOutNoRedirect } from 'common-front/src/util/aws/cognito';
import { Avatar } from 'common/src/components/avatar/avatar';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { Skeleton } from 'common/src/designSystem/components/skeleton';
import { MemberQuery } from 'common/src/generated/types';
import { MembersPaths } from 'common/src/util/paths/membersPaths';
import * as React from 'react';

interface IMemberHeaderProps {
    isLoading: boolean;
    userInfo: MemberQuery['userInfo'];
}

export const MemberHeader = (props: IMemberHeaderProps) => {
    const {
        history,
        translate,
        params: { organizationId, userInfoId }
    } = useHeavent();
    const isTablet = useTabletQuery();
    const isDesktop = useDesktopQuery();
    const avatarSize = isDesktop ? 160 : isTablet ? 96 : 64;

    return (
        <>
            <Box
                css={{
                    border: '4px solid white',
                    borderRadius: `${avatarSize + 8}px`,
                    boxShadow: '$lg',
                    position: 'absolute',
                    top: `-${Math.round(avatarSize * 0.3) + 24}px`
                }}
                height={avatarSize + 8}
                width={avatarSize + 8}
            >
                {props.isLoading ? (
                    <Skeleton size={avatarSize} variant="circular" />
                ) : (
                    <Avatar
                        email={props.userInfo.email}
                        image={props.userInfo.picture?.url}
                        name={props.userInfo.name}
                        size={avatarSize}
                    />
                )}
            </Box>

            <Flex
                css={{
                    '@mobile': { marginTop: '40px' },
                    '@tablet': { paddingLeft: '128px' },
                    '@desktop': { paddingLeft: '192px' }
                }}
                gap="4"
            >
                {props.isLoading ? (
                    <Skeleton borderRadius="$1" height={32} width={1} />
                ) : (
                    <>
                        <Box css={{ ellipsis: true, flex: '1' }} font="gray900 displayXs semiBold">
                            {translate('bienvenue_1_42255', props.userInfo.firstName || '')}
                        </Box>

                        <Dropdown>
                            <Trigger>
                                <Button color="white" leftIcon="ellipsis-vertical" />
                            </Trigger>

                            <Menu placement="bottom-end" width={230}>
                                <Flex
                                    align="center"
                                    css={{
                                        borderBottom: '1px solid $gray100',
                                        padding: '$2 $3'
                                    }}
                                    gap="2"
                                >
                                    <Avatar
                                        email={props.userInfo.email}
                                        image={props.userInfo.picture?.url}
                                        name={props.userInfo.name}
                                        size={40}
                                    />

                                    <Flex css={{ flex: '1' }} direction="column">
                                        <Box color="gray800" css={{ ellipsis: true }} width={1}>
                                            {props.userInfo.name}
                                        </Box>
                                        <Box color="gray500">ID : {userInfoId}</Box>
                                    </Flex>
                                </Flex>

                                <ItemIcon
                                    icon="arrow-down-arrow-up"
                                    onClick={() => {
                                        history.push(MembersPaths.PROFILES(organizationId));
                                    }}
                                >
                                    {translate('changer_de_prof_97199')}
                                </ItemIcon>

                                <ItemIcon
                                    color="red"
                                    icon="arrow-right-from-bracket"
                                    onClick={async () => {
                                        await signOutNoRedirect();

                                        location.href = MembersPaths.AUTH(organizationId);
                                    }}
                                >
                                    {translate('se_d_connecter_74151')}
                                </ItemIcon>
                            </Menu>
                        </Dropdown>
                    </>
                )}
            </Flex>
        </>
    );
};
