import { useParams } from 'common/src/util/dependencies/dependencies';
import * as React from 'react';
import { OrganizationPublicContextProvider } from '../../organizationPublicContext';
import { AuthForm } from '../authForm';
import { AsoAuthContainer } from './asoAuthContainer';
import { AsoAuthSkeleton } from './asoAuthSkeleton';

export const AsoAuth = () => {
    const { organizationId } = useParams();

    return (
        <OrganizationPublicContextProvider
            loadDelegationsSpace={false}
            loadMembersSpace={true}
            organizationId={organizationId}
            skeleton={<AsoAuthSkeleton />}
        >
            <AsoAuthContainer>
                <AuthForm
                    css={{
                        width: '100%',
                        px: '$6',
                        '@tablet': {
                            maxWidth: '100%',
                            px: '$12'
                        },
                        '@desktop': {
                            width: '$modalMd',
                            px: '$3'
                        }
                    }}
                    organizationId={organizationId}
                />
            </AsoAuthContainer>
        </OrganizationPublicContextProvider>
    );
};
