import { Item } from 'common-front/src/designSystem/components/dropdown/item';
import { Title } from 'common-front/src/designSystem/components/dropdown/title';
import { Separator } from 'common-front/src/designSystem/components/separator';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { DelegationsSpaceDelegationQuery } from 'common/src/generated/types';
import { isNonEmptyArray } from 'common/src/util/array';
import { CommonPaths } from 'common/src/util/paths/commonPaths';
import { DelegationsPaths } from 'common/src/util/paths/delegationsPaths';
import { groupBy, partition, sortBy } from 'lodash-es';
import * as React from 'react';

interface IDelegationMenuProps {
    delegations: DelegationsSpaceDelegationQuery['organization']['delegations']['nodes'];
}

export const DelegationMenu = (props: IDelegationMenuProps) => {
    const {
        translate,
        params: { organizationId, eventId, delegationId }
    } = useHeavent();
    const [eventsDelegations, communityDelegations] = React.useMemo(
        () => partition(props.delegations, (d) => typeof d.event?.id === 'number'),
        [props.delegations]
    );
    const grouped = React.useMemo(
        () =>
            sortBy(Object.values(groupBy(eventsDelegations, (d) => d.event!.id)), ([delegation]) =>
                delegation.event!.name.toLowerCase()
            ),
        [eventsDelegations]
    );

    if (props.delegations.length > 1) {
        return (
            <>
                {isNonEmptyArray(communityDelegations) && (
                    <Title>{translate('communaut_20896')}</Title>
                )}

                {communityDelegations.map((delegation) => (
                    <Item
                        key={delegation.id}
                        to={DelegationsPaths.DELEGATION({
                            organizationId,
                            delegationId: delegation.id
                        })}
                    >
                        {delegation.name}
                    </Item>
                ))}

                {grouped.map((delegations, index) => {
                    const firstDelegation = delegations[0];
                    const sorted = sortBy(delegations, (d) => d.name.toLowerCase());

                    return (
                        <React.Fragment key={index}>
                            <Title>{firstDelegation.event!.name}</Title>

                            {sorted.map((delegation) => (
                                <Item
                                    key={delegation.id}
                                    to={DelegationsPaths.DELEGATION({
                                        organizationId,
                                        eventId: delegation.event?.id,
                                        delegationId: delegation.id
                                    })}
                                >
                                    {delegation.name}
                                </Item>
                            ))}
                        </React.Fragment>
                    );
                })}

                <Separator direction="horizontal" color="gray100" />

                <Item to={CommonPaths.SHOW.LIST(DelegationsPaths.DELEGATIONS({ organizationId }))}>
                    {translate('voir_toutes_les_79250')}
                </Item>

                <Separator direction="horizontal" color="gray100" />

                <Item
                    to={DelegationsPaths.DELEGATION_PROFILE({
                        organizationId,
                        eventId,
                        delegationId
                    })}
                >
                    {translate('mon_profil_48224')}
                </Item>

                <Separator direction="horizontal" color="gray100" />
            </>
        );
    } else {
        return (
            <>
                <Item
                    to={DelegationsPaths.DELEGATION_PROFILE({
                        organizationId,
                        eventId,
                        delegationId
                    })}
                >
                    {translate('mon_profil_48224')}
                </Item>

                <Separator direction="horizontal" color="gray100" />
            </>
        );
    }
};
