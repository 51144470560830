import { useDesktopQuery } from 'common-front/src/hooks/useDesktopQuery';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { Spacer } from 'common/src/designSystem/components/spacer';
import * as React from 'react';

interface IFormContainerProps {
    bannerUrl?: string;
    children: React.ReactNode;
}

export const FormContainer = React.forwardRef(
    (props: IFormContainerProps, ref: React.Ref<HTMLDivElement>) => {
        const isDesktop = useDesktopQuery();
        const height = Math.min(Math.round((window.screen.width * 360) / 1170), 360);

        return (
            <Box ref={ref} css={{ background: '$gray50', overflowY: 'auto' }} height={1} width={1}>
                <Flex align="center" direction="column" width={1}>
                    {isDesktop && <Spacer height="10" />}

                    {props.bannerUrl && (
                        <>
                            <Box
                                css={{
                                    background: `url(${props.bannerUrl}) no-repeat`,
                                    backgroundSize: 'cover',
                                    height: `${height}px`,
                                    width: '100%',
                                    '@desktop': {
                                        borderRadius: '$1',
                                        boxShadow: '$xs',
                                        width: '$container'
                                    }
                                }}
                            />

                            <Spacer height={{ '@initial': '6', '@tablet': '7', '@desktop': '9' }} />
                        </>
                    )}

                    {props.children}
                </Flex>
            </Box>
        );
    }
);

FormContainer.displayName = 'FormContainer';
