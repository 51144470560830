import '0_reflect-metadata';
import { Authenticate } from 'common-front/src/authenticate';
import { Empty } from 'common-front/src/components/empty/empty';
import { ErrorBoundaryContextProvider } from 'common-front/src/components/errorBoundary/errorBoundaryContext';
import { MagicLink } from 'common-front/src/components/magicLink/magicLink';
import { MainLoader } from 'common-front/src/components/mainLoader/mainLoader';
import { MediaQueryProvider } from 'common-front/src/components/mediaQuery/mediaQueryContext';
import { UserContext } from 'common-front/src/userContext';
import { FrontDependenciesProvider } from 'common-front/src/util/dependencies/frontDependenciesProvider';
import { LogToken } from 'common-front/src/util/logToken';
import { Box } from 'common/src/designSystem/components/box';
import { CommonEnvVars } from 'common/src/envVars';
import { CommonPaths } from 'common/src/util/paths/commonPaths';
import { DelegationsPaths } from 'common/src/util/paths/delegationsPaths';
import { getEmbedPath, HeaventPaths, VolunteersPaths } from 'common/src/util/paths/heaventPaths';
import { MembersPaths } from 'common/src/util/paths/membersPaths';
import * as React from 'react';
import { render } from 'react-dom';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { DelegationForm } from './delegationForm';
import { RedirectDelegations } from './redirectDelegations';
import { RedirectMembers } from './redirectMembers';
import { Route404 } from './route404';
import { AuthRouter } from './v2/auth/authRouter';
import { Delegation } from './v2/delegations/delegation';
import { DelegationsList } from './v2/delegations/list/delegationsList';
import { Form } from './v2/forms/register/form';
import { MemberCampaignsList } from './v2/members/campaigns/memberCampaignsList';
import { MemberDocumentsList } from './v2/members/documents/memberDocumentsList';
import { Member } from './v2/members/member';
import { MemberSpace } from './v2/members/registrations/memberSpace';
import { UpdateMemberLoader } from './v2/members/update/updateMemberLoader';
import { ProfilesListRouter } from './v2/profiles/list/profilesListRouter';

const Authenticated = (props: { children: React.ReactNode; isEmbed: boolean }) => (
    <Authenticate
        getRedirectPath={({ organizationId, eventId, userInfoId, delegationId }) => {
            if (
                typeof organizationId === 'number' &&
                typeof eventId === 'number' &&
                !userInfoId &&
                !delegationId
            ) {
                return getEmbedPath(props.isEmbed, VolunteersPaths.AUTH(organizationId, eventId));
            } else if (
                location.pathname.includes('delegation') &&
                typeof organizationId === 'number'
            ) {
                return CommonPaths.AUTH(DelegationsPaths.DELEGATIONS({ organizationId }));
            } else if (
                location.pathname.startsWith('/v2/organization') &&
                typeof organizationId === 'number'
            ) {
                return MembersPaths.AUTH(organizationId);
            } else {
                return HeaventPaths.HOME;
            }
        }}
        shouldHaveUser={true}
    >
        {props.children}
    </Authenticate>
);

const Unauthenticated = (props: { children: React.ReactNode; isEmbed: boolean }) => (
    <Authenticate
        getRedirectPath={({ organizationId, eventId }) => {
            if (typeof organizationId === 'number' && typeof eventId === 'number') {
                return getEmbedPath(
                    props.isEmbed,
                    VolunteersPaths.ASSIGNMENTS(organizationId, eventId)
                );
            } else if (
                location.pathname.includes('delegations') &&
                typeof organizationId === 'number'
            ) {
                return CommonPaths.SHOW.LIST(DelegationsPaths.DELEGATIONS({ organizationId }));
            } else if (
                location.pathname.startsWith('/v2/organization') &&
                typeof organizationId === 'number'
            ) {
                return MembersPaths.PROFILES(organizationId);
            } else {
                return HeaventPaths.HOME;
            }
        }}
        shouldHaveUser={false}
    >
        {props.children}
    </Authenticate>
);

interface INormalRouterProps {
    isEmbed: boolean;
}

const NormalRouter = (props: INormalRouterProps) => (
    <Switch>
        {/* V2 */}
        <Route path={MembersPaths.TEAM_FORM(':organizationId', ':eventId', ':formId', ':teamCode')}>
            <Form />
        </Route>

        <Route
            path={[
                `/v2${DelegationsPaths.DELEGATION({
                    organizationId: ':organizationId',
                    eventId: ':eventId',
                    delegationId: ':delegationId'
                })}/form/:formId`,
                `${DelegationsPaths.DELEGATION({
                    organizationId: ':organizationId',
                    eventId: ':eventId',
                    delegationId: ':delegationId'
                })}/form/:formId`
            ]}
        >
            <DelegationForm />
        </Route>

        <Route
            path={[
                MembersPaths.DELEGATION_FORM(':organizationId', null, ':delegationId', ':formId'),
                MembersPaths.DELEGATION_FORM(
                    ':organizationId',
                    ':eventId',
                    ':delegationId',
                    ':formId'
                )
            ]}
        >
            <Form />
        </Route>

        <Route path={MembersPaths.EVENT_FORM(':organizationId', ':eventId', ':formId')}>
            <Form />
        </Route>

        <Route path={MembersPaths.ORGANIZATION_FORM(':organizationId', ':formId')}>
            <Form />
        </Route>

        <Route
            path={CommonPaths.AUTH(
                DelegationsPaths.DELEGATIONS({ organizationId: ':organizationId' })
            )}
        >
            <Unauthenticated isEmbed={false}>
                <AuthRouter loadDelegationsSpace={true} loadMembersSpace={false} />
            </Unauthenticated>
        </Route>

        <Route
            path={CommonPaths.SHOW.LIST(
                DelegationsPaths.DELEGATIONS({ organizationId: ':organizationId' })
            )}
        >
            <Authenticated isEmbed={false}>
                <DelegationsList />
            </Authenticated>
        </Route>

        <Route
            path={[
                DelegationsPaths.DELEGATION({
                    delegationId: ':delegationId',
                    organizationId: ':organizationId'
                }),
                DelegationsPaths.DELEGATION({
                    delegationId: ':delegationId',
                    eventId: ':eventId',
                    organizationId: ':organizationId'
                })
            ]}
        >
            <Authenticated isEmbed={false}>
                <Delegation />
            </Authenticated>
        </Route>

        <Route path={`/v2/organization/:organizationId/delegations`}>
            <RedirectDelegations />
        </Route>

        <Route path={MembersPaths.AUTH(':organizationId')}>
            <Unauthenticated isEmbed={false}>
                <AuthRouter loadDelegationsSpace={false} loadMembersSpace={true} />
            </Unauthenticated>
        </Route>

        <Route path={MembersPaths.PROFILES(':organizationId')}>
            <Authenticated isEmbed={false}>
                <ProfilesListRouter />
            </Authenticated>
        </Route>

        <Route path={MembersPaths.MEMBER_CAMPAIGNS(':organizationId', ':userInfoId')}>
            <Authenticated isEmbed={false}>
                <MemberCampaignsList />
            </Authenticated>
        </Route>

        <Route path={MembersPaths.MEMBER_DOCUMENTS(':organizationId', ':userInfoId')}>
            <Authenticated isEmbed={false}>
                <MemberDocumentsList />
            </Authenticated>
        </Route>

        <Route
            path={[
                MembersPaths.MEMBER_REGISTRATION_EDIT(':organizationId', ':userInfoId', null),
                MembersPaths.MEMBER_REGISTRATION_EDIT(':organizationId', ':userInfoId', ':eventId')
            ]}
        >
            <UpdateMemberLoader />
        </Route>

        <Route
            path={[
                MembersPaths.MEMBER_REGISTRATION({
                    organizationId: ':organizationId',
                    userInfoId: ':userInfoId'
                }),
                MembersPaths.MEMBER_REGISTRATION({
                    organizationId: ':organizationId',
                    userInfoId: ':userInfoId',
                    eventId: ':eventId'
                })
            ]}
        >
            <Authenticated isEmbed={false}>
                <MemberSpace />
            </Authenticated>
        </Route>

        <Route path={MembersPaths.MEMBER(':organizationId', ':userInfoId')}>
            <Authenticated isEmbed={false}>
                <Member />
            </Authenticated>
        </Route>

        <Route path={`/v2/organization/:organizationId`}>
            <RedirectMembers />
        </Route>

        {/* V1 */}

        <Route
            path={getEmbedPath(props.isEmbed, VolunteersPaths.AUTH(':organizationId', ':eventId'))}
        >
            <Empty push={({ organizationId }) => MembersPaths.AUTH(organizationId)} />
        </Route>

        <Route
            path={getEmbedPath(
                props.isEmbed,
                VolunteersPaths.REGISTER(':organizationId', ':eventId')
            )}
        >
            <Empty push={({ organizationId }) => MembersPaths.AUTH(organizationId)} />
        </Route>

        <Route>
            <Route404 />
        </Route>
    </Switch>
);

const Volunteers = () => (
    <Box
        css={{
            color: '$gray800',
            height: '100%',
            overflow: 'hidden',
            position: 'relative',
            width: '100%',
            '@desktop': {
                backgroundColor: '#f9fafb'
            }
        }}
    >
        <BrowserRouter basename={CommonEnvVars.HEAVENT_PUBLIC_URL.pathnameOptional}>
            <Switch>
                <Route
                    path={VolunteersPaths.MAGIC_LINK(
                        ':code',
                        ':source',
                        ':organizationId',
                        ':eventId'
                    )}
                >
                    <MagicLink />
                </Route>

                <Route path="/embed">
                    <NormalRouter isEmbed={true} />
                </Route>

                <Route>
                    <NormalRouter isEmbed={false} />
                </Route>
            </Switch>
        </BrowserRouter>
    </Box>
);

render(
    <React.StrictMode>
        <MainLoader
            render={(userContext) => (
                <UserContext.Provider value={userContext}>
                    <FrontDependenciesProvider>
                        <MediaQueryProvider>
                            <ErrorBoundaryContextProvider>
                                <LogToken />

                                <Volunteers />
                            </ErrorBoundaryContextProvider>
                        </MediaQueryProvider>
                    </FrontDependenciesProvider>
                </UserContext.Provider>
            )}
        />
    </React.StrictMode>,
    document.getElementById('heavent-react-hook')
);
