import { Button } from 'common-front/src/designSystem/components/button';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { MemberQuery } from 'common/src/generated/types';
import { MembersPaths } from 'common/src/util/paths/membersPaths';
import * as React from 'react';

interface IUserInfoAssignmentsProps {
    organization: MemberQuery['organization'];
    userInfo: MemberQuery['userInfo'];
}

export const UserInfoAssignments = ({ organization }: IUserInfoAssignmentsProps) => {
    const {
        history,
        params: { organizationId, userInfoId },
        translate
    } = useHeavent();

    return (
        <Flex
            css={{
                bd: true,
                overflow: 'hidden'
            }}
            direction="column"
        >
            <Flex
                align={{ '@tablet': 'center' }}
                css={{
                    background: 'white',
                    borderBottom: '1px solid $gray200',
                    padding: '$6 $4',
                    '@tablet': {
                        padding: '$6'
                    }
                }}
                direction={{ '@initial': 'column', '@tablet': 'row' }}
                gap="4"
            >
                <Flex css={{ flex: '1' }} direction="column" gap="1">
                    <Box color="gray800" fontSize="textMd" fontWeight="medium">
                        {organization.name}
                    </Box>
                </Flex>

                <Button
                    color="white"
                    leftIcon="eye"
                    onClick={() => {
                        history.push(
                            MembersPaths.MEMBER_REGISTRATION({ organizationId, userInfoId })
                        );
                    }}
                >
                    {translate('voir_le_d_tail_72056')}
                </Button>
            </Flex>
        </Flex>
    );
};
