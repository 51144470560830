import { Blank } from 'common-front/src/components/blank/blank';
import { Button } from 'common-front/src/designSystem/components/button';
import { Separator } from 'common-front/src/designSystem/components/separator';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { Svgs } from 'common-front/src/util/assets';
import { signOut } from 'common-front/src/util/aws/cognito';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { heaventEnvDisplayName } from 'common/src/heaventEnv';
import { isNonEmptyArray } from 'common/src/util/array';
import { DelegationsPaths } from 'common/src/util/paths/delegationsPaths';
import { groupBy } from 'lodash-es';
import * as React from 'react';
import { useDelegationsListQuery } from '../../../generated/graphqlHooks';
import { OrganizationLogo } from '../../organizationLogo';
import { OrganizationPublicContextProvider } from '../../organizationPublicContext';
import {
    ProfilesListContainer,
    ProfilesListSkeleton
} from '../../profiles/list/profilesListSkeleton';
import { DelegationsListDelegation } from './delegationsListDelegation';

export const DelegationsList = () => {
    const {
        history,
        translate,
        params: { organizationId }
    } = useHeavent();
    const { data, isLoading } = useDelegationsListQuery({ organizationId });
    const { eventDelegationsGroupedByEvent, organizationDelegations } = React.useMemo(() => {
        const organizationDelegations = (data.organization?.delegations.nodes ?? []).filter(
            (delegation) => !delegation.eventId
        );
        const eventDelegationsGroupedByEvent = groupBy(
            (data.organization?.delegations.nodes ?? []).filter((delegation) => delegation.event),
            (delegation) => delegation.eventId
        );
        return { eventDelegationsGroupedByEvent, organizationDelegations };
    }, [data.organization]);

    React.useEffect(() => {
        if (data.organization?.delegations.nodes.length === 1) {
            const delegation = data.organization.delegations.nodes[0];

            history.replace(
                DelegationsPaths.DELEGATION({
                    organizationId,
                    eventId: delegation.eventId || undefined,
                    delegationId: delegation.id
                })
            );
        }
    }, [data.organization]);

    React.useEffect(() => {
        document.title = data.organization?.name ?? heaventEnvDisplayName();
    }, [data.organization]);

    return (
        <OrganizationPublicContextProvider
            isLoading={isLoading}
            loadDelegationsSpace={true}
            loadMembersSpace={false}
            organizationId={organizationId}
            skeleton={<ProfilesListSkeleton />}
        >
            <ProfilesListContainer>
                <OrganizationLogo />

                <Flex
                    css={{
                        width: '100%',
                        '@desktop': {
                            width: '$modalMd'
                        }
                    }}
                    direction="column"
                    gap="3"
                >
                    {isNonEmptyArray(data.organization?.delegations.nodes) ? (
                        <>
                            <Box color="gray800" fontSize="textMd" fontWeight="semiBold">
                                {translate('quelle_d_l_gati_13930')}
                            </Box>

                            <Spacer width="6" />

                            {isNonEmptyArray(organizationDelegations) && (
                                <>
                                    <Box color="gray800" fontSize="textSm" fontWeight="semiBold">
                                        {data.organization?.name}
                                    </Box>

                                    {organizationDelegations.map((delegation) => (
                                        <DelegationsListDelegation
                                            key={delegation.id}
                                            delegation={delegation}
                                        />
                                    ))}
                                </>
                            )}

                            {isNonEmptyArray(Object.values(eventDelegationsGroupedByEvent)) && (
                                <>
                                    {isNonEmptyArray(organizationDelegations) && (
                                        <Separator direction="horizontal" />
                                    )}

                                    <Box font="gray800 textSm semiBold">
                                        {translate('_v_nements_53672')}
                                    </Box>

                                    {Object.values(eventDelegationsGroupedByEvent).map(
                                        (delegations) => (
                                            <React.Fragment key={delegations[0].eventId!}>
                                                <Box font="gray500 textXs semiBold">
                                                    {delegations[0].event!.name}
                                                </Box>

                                                {delegations.map((delegation) => (
                                                    <DelegationsListDelegation
                                                        key={delegation.id}
                                                        delegation={delegation}
                                                    />
                                                ))}
                                            </React.Fragment>
                                        )
                                    )}
                                </>
                            )}
                        </>
                    ) : (
                        <Flex align="center" direction="column">
                            <Blank
                                imageSrc={Svgs.FormsElementsBlank}
                                title={translate('aucune_d_l_gati_85273')}
                            />

                            <Flex>
                                <Button color="white" onClick={signOut}>
                                    {translate('se_d_connecter_74151')}
                                </Button>
                            </Flex>
                        </Flex>
                    )}
                </Flex>
            </ProfilesListContainer>
        </OrganizationPublicContextProvider>
    );
};
