import { CommonEnvVars } from 'common/src/envVars';
import { OrganizationId } from 'common/src/generated/types';
import { HeaventEnv } from 'common/src/heaventEnv';
import {
    PARTAGE_TA_PASSION_HOST,
    thirdPartyDomainToOrganizationId
} from 'common/src/thirdPartyDomains';

export function isPartagetapassionCom(organizationId: OrganizationId) {
    try {
        return (
            CommonEnvVars.HEAVENT_ENV === HeaventEnv.Recrewteer &&
            location.host === PARTAGE_TA_PASSION_HOST &&
            organizationId === thirdPartyDomainToOrganizationId[PARTAGE_TA_PASSION_HOST]
        );
    } catch {
        return false;
    }
}
