import { Empty } from 'common-front/src/components/empty/empty';
import { TabViewResponsive } from 'common-front/src/components/tabViewsReponsive/tabViewResponsive';
import { EventDelegationMembers } from 'common-front/src/delegations/members/eventDelegationMembers';
import { OrganizationDelegationMembers } from 'common-front/src/delegations/members/organizationDelegationMembers';
import { DelegationAccreditationsLoader } from 'common-front/src/delegations/show/accreditations/delegationAccreditationsLoader';
import { DelegationDocuments } from 'common-front/src/delegations/show/documents/delegationDocuments';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { Flex } from 'common/src/designSystem/components/flex';
import { DelegationState } from 'common/src/generated/types';
import { DelegationParams, DelegationsPaths } from 'common/src/util/paths/delegationsPaths';
import { compact } from 'lodash-es';
import * as React from 'react';
import { Route, Switch } from 'react-router';
import { useDelegationsSpaceDelegationQuery } from '../../generated/graphqlHooks';
import { SpaceContainer } from '../common/spaceContainer';
import { DelegationDashboard } from './dashboard/delegationDashboard';
import { DelegationMenu } from './delegationMenu';
import { DelegationsSpaceHeader } from './delegationsSpaceHeader';
import { DelegationUpdateFields } from './fields/update/delegationUpdateFields';
import {
    DelegationCreateMember,
    DelegationUpdateMember
} from './members/update/delegationCreateUpdateMember';
import { DelegationProfile } from './profile/delegationProfile';

export const Delegation = () => {
    const {
        translate,
        params: { organizationId, eventId, delegationId }
    } = useHeavent();
    const { data, loader, reload } = useDelegationsSpaceDelegationQuery({
        delegationId,
        organizationId
    });
    const extraItems = React.useMemo(() => {
        const delegations = data.organization?.delegations.nodes ?? [];

        return <DelegationMenu delegations={delegations} />;
    }, [data.organization]);
    const canEditMembers = React.useMemo(() => {
        if (data.organization?.delegation) {
            return (
                data.organization.delegation.canLeadersEditMembers &&
                data.organization.delegation.state !== DelegationState.Locked
            );
        } else {
            return false;
        }
    }, [data.organization]);
    const getDelegationParams = (isRouteComponent: boolean): DelegationParams => ({
        organizationId: isRouteComponent ? ':organizationId' : organizationId,
        eventId: eventId ? (isRouteComponent ? ':eventId' : eventId) : undefined,
        delegationId: isRouteComponent ? ':delegationId' : delegationId
    });

    React.useEffect(() => {
        const eventName =
            data.organization?.delegation?.event?.name ?? data.organization?.name ?? 'Recrewteer';

        document.title = compact([eventName, data.organization?.delegation?.name]).join(' - ');
    }, [data.organization]);

    return (
        loader || (
            <SpaceContainer
                extraItems={extraItems}
                isLoading={false}
                organization={data.organization}
                spaceDesign={{
                    themeColors: data.organization?.delegationsSpaceDesign?.colors,
                    bannerSrc: data.organization?.delegationsSpaceDesign?.banner?.url,
                    logoSrc: data.organization?.delegationsSpaceDesign?.logo?.url
                }}
                userInfo={data.user.userInfo}
            >
                <Switch>
                    <Route
                        exact
                        path={[
                            DelegationsPaths.DELEGATION({
                                organizationId: ':organizationId',
                                delegationId: ':delegationId'
                            }),
                            DelegationsPaths.DELEGATION({
                                organizationId: ':organizationId',
                                eventId: ':eventId',
                                delegationId: ':delegationId'
                            })
                        ]}
                    >
                        <Empty
                            path={DelegationsPaths.DELEGATION_MEMBERS({
                                organizationId,
                                eventId,
                                delegationId
                            })}
                            replace={true}
                        />
                    </Route>

                    <Route
                        path={[
                            DelegationsPaths.MEMBER_EDIT({
                                organizationId: ':organizationId',
                                delegationId: ':delegationId',
                                userInfoId: ':userInfoId',
                                formId: ':formId'
                            }),
                            DelegationsPaths.MEMBER_EDIT({
                                organizationId: ':organizationId',
                                eventId: ':eventId',
                                delegationId: ':delegationId',
                                userInfoId: ':userInfoId',
                                formId: ':formId'
                            })
                        ]}
                    >
                        <DelegationUpdateMember reload={reload} />
                    </Route>

                    <Route
                        path={[
                            DelegationsPaths.MEMBER_CREATE({
                                organizationId: ':organizationId',
                                delegationId: ':delegationId',
                                formId: ':formId'
                            }),
                            DelegationsPaths.MEMBER_CREATE({
                                organizationId: ':organizationId',
                                eventId: ':eventId',
                                delegationId: ':delegationId',
                                formId: ':formId'
                            })
                        ]}
                    >
                        <DelegationCreateMember reload={reload} />
                    </Route>

                    <Route
                        path={[
                            DelegationsPaths.DELEGATION_PROFILE({
                                organizationId: ':organizationId',
                                delegationId: ':delegationId'
                            }),
                            DelegationsPaths.DELEGATION_PROFILE({
                                organizationId: ':organizationId',
                                eventId: ':eventId',
                                delegationId: ':delegationId'
                            })
                        ]}
                    >
                        <DelegationProfile />
                    </Route>

                    <Route
                        path={[
                            DelegationsPaths.FIELDS_EDIT({
                                organizationId: ':organizationId',
                                delegationId: ':delegationId'
                            }),
                            DelegationsPaths.FIELDS_EDIT({
                                organizationId: ':organizationId',
                                eventId: ':eventId',
                                delegationId: ':delegationId'
                            })
                        ]}
                    >
                        <DelegationUpdateFields />
                    </Route>

                    <Route>
                        <>
                            <DelegationsSpaceHeader
                                delegation={data.organization.delegation}
                                name={data.user.userInfo.firstName}
                            />

                            <Flex direction="column">
                                <TabViewResponsive.Root>
                                    <TabViewResponsive.Section
                                        getPath={(isRouteComponent: boolean = false) =>
                                            DelegationsPaths.DELEGATION_DASHBOARD(
                                                getDelegationParams(isRouteComponent)
                                            )
                                        }
                                        title={translate('tableau_de_bord_24627')}
                                    >
                                        <DelegationDashboard />
                                    </TabViewResponsive.Section>

                                    <TabViewResponsive.Section
                                        getPath={(isRouteComponent: boolean = false) =>
                                            DelegationsPaths.DELEGATION_MEMBERS(
                                                getDelegationParams(isRouteComponent)
                                            )
                                        }
                                        title={translate(
                                            'membres_125561',
                                            data.organization.delegation.numberOfResources
                                        )}
                                    >
                                        {eventId ? (
                                            <EventDelegationMembers
                                                canAccredit={false}
                                                canClickMemberName={false}
                                                canEditMembers={canEditMembers}
                                                columns={data.organization.delegation.columns}
                                                customBadges={
                                                    data.organization.delegationsSpaceCustomBadges
                                                }
                                                deleteFromEvent={true}
                                                formId={data.organization.delegation.formId}
                                                getEditPath={(member) =>
                                                    DelegationsPaths.MEMBER_EDIT({
                                                        delegationId,
                                                        eventId,
                                                        organizationId,
                                                        userInfoId: member.userInfo.id,
                                                        formId: data.organization.delegation.formId
                                                    })
                                                }
                                                isEventAdmin={false}
                                                showDeleteFromEvent={false}
                                                showDocumentsDownload={
                                                    data.organization.allowDelegationSpaceDownload
                                                }
                                            />
                                        ) : (
                                            <OrganizationDelegationMembers
                                                canClickMemberName={false}
                                                canEditMembers={canEditMembers}
                                                customBadges={
                                                    data.organization.delegationsSpaceCustomBadges
                                                }
                                                formId={data.organization.delegation.formId}
                                                getEditPath={(member) =>
                                                    DelegationsPaths.MEMBER_EDIT({
                                                        delegationId,
                                                        organizationId,
                                                        userInfoId: member.id,
                                                        formId: data.organization.delegation.formId
                                                    })
                                                }
                                                isOrganizationAdmin={false}
                                                showDocumentsDownload={
                                                    data.organization.allowDelegationSpaceDownload
                                                }
                                            />
                                        )}
                                    </TabViewResponsive.Section>

                                    {eventId && (
                                        <TabViewResponsive.Section
                                            getPath={(isRouteComponent: boolean = false) =>
                                                DelegationsPaths.DELEGATION_ACCREDITATIONS(
                                                    getDelegationParams(isRouteComponent)
                                                )
                                            }
                                            title={translate('accr_ditations_39450')}
                                        >
                                            <DelegationAccreditationsLoader />
                                        </TabViewResponsive.Section>
                                    )}

                                    {eventId && data.organization.allowDelegationSpaceDownload && (
                                        <TabViewResponsive.Section
                                            getPath={(isRouteComponent: boolean = false) =>
                                                DelegationsPaths.DELEGATION_DOCUMENTS(
                                                    getDelegationParams(isRouteComponent)
                                                )
                                            }
                                            title={translate('documents_87028')}
                                        >
                                            <DelegationDocuments
                                                customDocuments={
                                                    data.organization.delegation.event
                                                        ?.customDocuments ?? []
                                                }
                                                delegationsSpaceCustomBadges={
                                                    data.organization.delegationsSpaceCustomBadges
                                                }
                                            />
                                        </TabViewResponsive.Section>
                                    )}
                                </TabViewResponsive.Root>
                            </Flex>
                        </>
                    </Route>
                </Switch>
            </SpaceContainer>
        )
    );
};
