import { Button } from 'common-front/src/designSystem/components/button';
import { Modal } from 'common-front/src/designSystem/components/modal';
import { useMobileQuery } from 'common-front/src/hooks/useMobileQuery';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { I } from 'common/src/designSystem/components/i';
import { CampaignType, MemberCampaignsQuery } from 'common/src/generated/types';
import { DateTimeService } from 'common/src/services/dateTimeService';
import { useService, useTranslate } from 'common/src/util/dependencies/dependencies';
import { LocaleFormats } from 'common/src/util/luxon';
import * as React from 'react';

interface IMemberCampaignsCampaignProps {
    campaign: MemberCampaignsQuery['userInfo']['campaigns']['nodes'][0];
}

export const MemberCampaignsCampaign = (props: IMemberCampaignsCampaignProps) => {
    const translate = useTranslate();
    const dateTimeService = useService(DateTimeService);
    const isMobile = useMobileQuery();
    const [isPreviewOpen, setIsPreviewOpen] = React.useState(false);
    const iframeRef = React.useCallback((node) => {
        if (node) {
            node.onload = () => {
                const height = node.contentDocument.body.scrollHeight;
                node.style.height = `${height}px`;
                node.contentDocument.body.style.margin = '0';
                node.contentDocument.body.style.overflow = 'hidden';
                node.parentElement.style.height = `${height + 32 * 2}px`;
            };
        }
    }, []);
    const onClose = React.useCallback(() => {
        setIsPreviewOpen(false);
    }, []);

    return (
        <Flex
            css={{
                border: '1px solid $gray200',
                borderRadius: '$2',
                bowShadow: '$xs',
                overflow: 'hidden'
            }}
            direction="column"
        >
            <Flex
                align={{ '@initial': 'start', '@tablet': 'center' }}
                css={{ padding: '$4 $6' }}
                direction={{ '@initial': 'column', '@tablet': 'row' }}
                gap="4"
            >
                <Flex align="center" css={{ flex: '1' }} gap="4">
                    <Flex
                        align="center"
                        css={{
                            background: '$gray100',
                            borderRadius: '$2',
                            color: '$gray700'
                        }}
                        height={40}
                        justify="center"
                        width={40}
                    >
                        <I
                            icon={
                                props.campaign.campaignType === CampaignType.Sms ? 'message' : 'at'
                            }
                        />
                    </Flex>

                    <Flex css={{ flex: '1' }} direction="column" gap="1">
                        <Box font="gray800 textSm medium">{props.campaign.name}</Box>

                        {props.campaign.sentAt && (
                            <Box color="gray500">
                                {translate(
                                    're_u_le_1_41629',
                                    dateTimeService.toLocaleString(
                                        props.campaign.sentAt,
                                        LocaleFormats.DateOnly.MonthLong
                                    )
                                )}
                            </Box>
                        )}
                    </Flex>
                </Flex>

                <Button
                    color="white"
                    leftIcon={isMobile ? 'eye' : isPreviewOpen ? 'chevron-down' : 'chevron-right'}
                    onClick={() => {
                        setIsPreviewOpen(!isPreviewOpen);
                    }}
                >
                    {isMobile && translate('lire_le_message_68344')}
                </Button>
            </Flex>

            {!isMobile && isPreviewOpen && (
                <Flex
                    css={{
                        background: '$gray50',
                        minHeight: '120px',
                        '@tablet': {
                            padding: '$7 $9'
                        },
                        '@desktop': {
                            padding: '$7 $13'
                        },
                        '& iframe': {
                            background: 'white',
                            border: 'none',
                            borderRadius: '$2',
                            overflow: 'hidden',
                            width: '100%'
                        }
                    }}
                >
                    <iframe ref={iframeRef} srcDoc={props.campaign.preview} />
                </Flex>
            )}

            {isMobile && isPreviewOpen && (
                <Modal
                    buttons={
                        <>
                            <Button onClick={onClose}>{translate('fermer_62479')}</Button>
                        </>
                    }
                    size="md"
                    onClose={onClose}
                >
                    <Flex
                        css={{
                            height: '100%',
                            width: '100%',
                            '& iframe': {
                                background: 'white',
                                border: 'none',
                                borderRadius: '$2',
                                overflow: 'hidden',
                                width: '100%'
                            }
                        }}
                    >
                        <iframe ref={iframeRef} srcDoc={props.campaign.preview} />
                    </Flex>
                </Modal>
            )}
        </Flex>
    );
};
