import { FormErrors } from 'common-front/src/components/form/formErrors';
import { Button } from 'common-front/src/designSystem/components/button';
import { FormSlots } from 'common-front/src/forms/slots/formSlots';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { RichText } from 'common/src/designSystem/components/richEditor/richText';
import { Spacer } from 'common/src/designSystem/components/spacer';
import {
    EventId,
    FormId,
    FormInfosQuery,
    OrganizationId,
    VolunteersRegistrationsSlotInput
} from 'common/src/generated/types';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import { isNonEmptyString } from 'common/src/util/string';
import * as React from 'react';
import { useFormSlotsQuery } from '../../../../generated/graphqlHooks';

interface IFormSlotsLoaderProps {
    currentSlots: VolunteersRegistrationsSlotInput[];
    eventId: EventId;
    form: FormInfosQuery['organization']['form'];
    formId: FormId;
    isFinalStep: boolean;
    organizationId: OrganizationId;
    registerButtonText: string;

    change(name: string, value: any): void;
    handleSubmit(): void;
}

export const FormSlotsLoader = (props: IFormSlotsLoaderProps) => {
    const translate = useTranslate();
    const { data, loader } = useFormSlotsQuery({
        organizationId: props.organizationId,
        formId: props.formId,
        eventId: props.eventId
    });

    return (
        loader || (
            <>
                {isNonEmptyString(props.form.slotSubtitle) ? (
                    <RichText text={props.form.slotSubtitle} />
                ) : (
                    <>
                        <Box font="gray800 textMd semiBold">
                            {translate('s_lectionnez_vo_77037')}
                        </Box>

                        <Spacer height="1" />

                        <Box color="gray500">{translate('vos_disponibili_52929')}</Box>
                    </>
                )}

                <Spacer height="6" />

                <FormErrors />

                <FormSlots
                    change={props.change}
                    currentSlots={props.currentSlots}
                    customSlotsPeriods={data.organization.form.customSlotsPeriods}
                    daysDisplay={props.form.daysDisplay}
                    event={data.event}
                    prefix="formRegister."
                    slotDisplay={props.form.slotDisplay}
                />

                <Spacer height="7" />

                <Flex>
                    <Button onClick={props.handleSubmit}>
                        {props.isFinalStep ? props.registerButtonText : translate('suivant_62774')}
                    </Button>
                </Flex>
            </>
        )
    );
};
