import { Empty } from 'common-front/src/components/empty/empty';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { CommonPaths } from 'common/src/util/paths/commonPaths';
import { DelegationsPaths } from 'common/src/util/paths/delegationsPaths';
import * as React from 'react';

export const RedirectDelegations = () => {
    const {
        params: { organizationId }
    } = useHeavent();

    return <Empty path={CommonPaths.AUTH(DelegationsPaths.DELEGATIONS({ organizationId }))} />;
};
