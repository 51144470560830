import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { isPartagetapassionCom } from 'common-front/src/thirdPartyDomains';
import * as React from 'react';
import { AsoProfilesList } from './aso/asoProfilesList';
import { ProfilesList } from './profilesList';

export const ProfilesListRouter = () => {
    const {
        params: { organizationId }
    } = useHeavent();

    if (isPartagetapassionCom(organizationId)) {
        return <AsoProfilesList />;
    } else {
        return <ProfilesList />;
    }
};
